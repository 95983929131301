import React, { useEffect, useState } from "react"
import "../../../assets/styles/page/docproject.scss"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import ArticleCard from "../../../components/ArticleCard"
import SEO from "../../../components/seo/seo"

interface Project {
  titleOfProject: string;
  projectDescription: string;
  ProjectCategory: string;
  bannerUrl: {
    url: string;
  };
  _id: string;
  slug: string;
  seo: {
    title: string;
    description: string;
    image: {
      url: string;
    };
  };
}

const Project = () => {
  const [projects, setProjects] = useState<Project[]>([])
  const [metaData, setMetaData] = useState<Project['seo'] | null>(null)

  useEffect(() => {
    const fetchProjects = async () => {
      const query = `
        query projectInfo {
          projectCollection {
            items {
              seo {
                ... on Seo {
                  title
                  description
                  image {
                    url
                  }
                }
              }
              ... on Project {
                projectDescription
                slug
                titleOfProject
                _id
                bannerUrl {
                  url
                }
              }
            }
          }
        }
      `
      try {
        const { default: ContentfulService } = await import("../../../services/contentful.service")
        const response = await ContentfulService.callContentfulApi(query)
        const projects = response.data.projectCollection?.items || []
        setProjects(projects)
        setMetaData(projects[0]?.seo)
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    }

    fetchProjects()
  }, [])

  if (!projects.length || !metaData) {
    return <div>Loading...</div>
  }

  return (
    <>
      <SEO title={metaData.title} description={metaData.description} image={metaData.image.url} />
      <Layout>
        <div className="project--page--cointainer">
          <div className="project--header">
            <h1 className="project--title">Project</h1>
            <p className="project--title--subtitles">
              Discover our latest releases and download our community resources
            </p>
          </div>
          <div className="project--list">
            {projects.map(project => {
              const { titleOfProject, projectDescription, ProjectCategory, bannerUrl, _id, slug } = project
              return (
                <Link
                  to={`/doc/project/doc-projects/${slug}`}
                  className="project--card--wrapper--container"
                  key={_id}
                >
                  <ArticleCard
                    key={_id}
                    articleImage={bannerUrl.url}
                    articleHeadtext={titleOfProject}
                    articleSubtext={projectDescription}
                    meta={ProjectCategory}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Project
